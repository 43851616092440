import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { AUTH_SERVICE_URL,SERVER_HOST } from "../../constants";
import {
  LOGIN_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VERIFY_OTP
} from "../actions";

import {
  loginUserSuccess,
  loginUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  verifyOTPSuccess,
  verifyOTPError
} from "./actions";

import { adminRoot } from "../../constants/defaultValues";
import { setCurrentUser,getCurrentUser } from "../../helpers/Utils";
const domain = new URL(SERVER_HOST).hostname;

export function* watchVerifyOtp() {
  yield takeEvery(VERIFY_OTP, verifyWithOTP);
}

const verifyOTPAsync = async (otp,email,domain) => {
  const result = await axios.post(
    `${AUTH_SERVICE_URL}/verify-otp`,
    {
      otp,email,domain
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  console.log('result:::', result.data);
  return result;
};

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password,domain) => {
  const result = await axios.post(
    `${AUTH_SERVICE_URL}/login`,
    {
      email,
      password,
      domain
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  console.log("result:::", result.data);
  return result.data;
};

function* loginWithEmailPassword({ payload }) {
  let { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password,domain);    
    if (loginUser.status) {
        const verifyOtpStatus = loginUser?.data?.message;
        const item = {
          uuid: loginUser?.data?.uuid,
          name: loginUser?.data?.displayName,
          email: loginUser?.data?.email || email,
          emailVerified: loginUser?.data?.registered,
          token: loginUser?.data?.token,
          refreshToken: loginUser?.data?.refreshToken
        };
        if(!verifyOtpStatus){
        setCurrentUser(item);
        history.push(adminRoot)
      }else{
      window.localStorage.setItem('email', email);
      yield put(loginUserSuccess(item));
      history.push('/user/verify-otp');
      }
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error?.response?.data?.message));
  }
}

function* verifyWithOTP({ payload }) {
  let { otp } = payload.user;
  const { history } = payload;
  try {
    const email = window.localStorage.getItem('email');
    const verifyOTP = yield call(verifyOTPAsync, otp,email,domain);
    if (verifyOTP.status) {
      const itemData = verifyOTP.data.data.userData;
      setCurrentUser(itemData);
      yield put(verifyOTPSuccess(itemData));
      history.push(adminRoot);
      window.localStorage.removeItem('email')
    } else {
      yield put(verifyOTPError(verifyOTP?.data?.message));
    }
  } catch (error) {
    yield put(verifyOTPError(error?.response?.data?.message));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  const loggedInUser = getCurrentUser();
  if(loggedInUser){
  const { uuid, token } = loggedInUser;
  const result = await axios.post(`${AUTH_SERVICE_URL}/logout`,
  {
    uuid,
  }, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": 'Bearer ' + token.trim(),
    },
  });
  console.log("resultLogout:::", result.data);
}
  setCurrentUser();
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess("success"));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess("success"));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  // console.log('rootSag');
  yield all([
    fork(watchLoginUser),
    fork(watchVerifyOtp),
    fork(watchLogoutUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
