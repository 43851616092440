import { UserRole } from '../helpers/authHelper';

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'ar', name: 'Arabic', direction: 'rtl' }
];

const firebaseConfigQA = {
  apiKey: "AIzaSyC4wof5tD1gm6SQsM1gnJ4QgE_kncX6MZc",
  authDomain: "styli-dev-251710.firebaseapp.com",
  databaseURL: 'https://styli-dev-251710.firebaseio.com',
  projectId: "styli-dev-251710",
  storageBucket: "styli-dev-251710.appspot.com",
  messagingSenderId: 'G-5SMJ9MYBPM',
  appId: '1:903334203234:web:a1ed9a611d18f13913e63b'
};
const firebaseConfigDev = {
  apiKey: "AIzaSyC4wof5tD1gm6SQsM1gnJ4QgE_kncX6MZc",
  authDomain: "styli-dev-251710.firebaseapp.com",
  databaseURL: "https://styli-dev-251710.firebaseio.com",
  projectId: "styli-dev-251710",
  storageBucket: "styli-dev-251710.appspot.com",
  messagingSenderId: "903334203234",
  appId: "1:903334203234:web:8e3b22a94856257213e63b",
  measurementId: "G-WZTQJ8014Z"
};
const firebaseConfigProd = {
  apiKey: 'AIzaSyBE5jksNFb01168sKeTRjgfInnBQNDtUXU',
  authDomain: 'stylishopprod.firebaseapp.com',
  projectId: 'stylishopprod',
  storageBucket: 'stylishopprod.appspot.com',
  messagingSenderId: '476895285940',
  appId: '1:476895285940:web:05ba0386de4ee44eba3de1',
  measurementId: 'G-RY49YE7HM2'
};
const firebaseConfigUAT = {
  apiKey: 'AIzaSyBYAfWIyGi3aR8BI4RLGSURQSiu_1O7FqY',
  authDomain: 'supplyqa01.firebaseapp.com',
  projectId: 'supplyqa01',
  storageBucket: 'supplyqa01.appspot.com',
  messagingSenderId: '108359217635',
  appId: '1:108359217635:web:c80e2030cd49cbaa4070d6'
};


export const firebaseConfig =
  process.env.NODE_ENV === 'development'
    ? firebaseConfigDev
    : process.env.NODE_ENV === 'uat'
    ? firebaseConfigUAT
    : process.env.NODE_ENV === 'production'
    ? firebaseConfigProd
    : firebaseConfigQA;

// export const firebaseConfig = firebaseConfigLocal;

export const currentUser = {
  id: 1,
  title: '',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin
};

export const adminRoot = '/app';
export const otpRoot = '/user/verify-otp'
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = process.env.MULIN_API_HOST;

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel'
];